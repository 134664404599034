@import "../../../../ba_gp_storefront_core/cartridge/scss/default/mixin/breakpoint";
@import "components/variables";
@import "components/mixins";
/* ============================================================================
Carousel component
============================================================================ */

$ex: ex-static-product-carousel;

.#{$ex} {
    display: flex;
    justify-content: center;
    margin-top: var(--container-mt);
    margin-bottom: var(--container-mb);
    padding-top: var(--container-pt);
    padding-right: var(--container-pr);
    padding-bottom: var(--container-pb);
    padding-left: var(--container-pl);

    @include bp(min-width, $bp-medium) {
        margin-top: var(--container-mt-md);
        margin-bottom: var(--container-mb-md);
        padding-top: var(--container-pt-md);
        padding-right: var(--container-pr-md);
        padding-bottom: var(--container-pb-md);
        padding-left: var(--container-pl-md);
    }

    @include bp(min-width, $bp-large) {
        margin-top: var(--container-mt-lg);
        margin-bottom: var(--container-mb-lg);
        padding-top: var(--container-pt-lg);
        padding-right: var(--container-pr-lg);
        padding-bottom: var(--container-pb-lg);
        padding-left: var(--container-pl-lg);
    }

    &.carousel-initialized,
    &.consent-tracking {
        .#{$ex} {
            &__controls {
                display: block;
            }

            &__owl-carousel {
                display: block;
            }
        }
    }

    &__row {
        position: relative;
        margin: auto;
        max-width: var(--carousel-max-width);
        width: 100%;

        @include bp(min-width, $bp-medium) {
            max-width: var(--carousel-max-width-md);
        }

        @include bp(min-width, $bp-large) {
            max-width: var(--carousel-max-width-lg);
        }
    }

    &__controls {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: transparent;
    }

    &__owl-carousel {
        display: none;
    }

    .custom-owl-prev {
        left: -50px;
        transform: translateY(-50%) rotate(135deg);

        @include bp(min-width, $bp-large) {
            left: -40px;
        }
    }

    .custom-owl-next {
        right: -50px;
        transform: translateY(-50%) rotate(-45deg);

        @include bp(min-width, $bp-large) {
            right: -40px;
        }
    }

    .custom-owl-prev,
    .custom-owl-next {
        display: none;
        position: absolute;
        top: 50%;
        border-right: 1px solid $black;
        border-bottom: 1px solid $black;
        cursor: pointer;

        @include bp(min-width, $bp-medium) {
            display: block;
            width: 14px;
            height: 14px;
        }

        @include bp(min-width, $bp-large) {
            width: 22.5px;
            height: 22.5px;
        }
    }

    .owl-stage {
        display: flex;
        margin-left: -30px;

        @include bp(min-width, $bp-medium) {
            margin-left: 0;
        }
    }

    .owl-item {
        display: flex;
        flex: 1 0 auto;
    }

    .owl-controls {
        display: none;
    }
}

.spc-cta-only {
    .#{$ex} {
        &__controls {
            @include bp(min-width, $bp-medium) {
                bottom: 28px;
            }

            @include bp(min-width, $bp-large) {
                bottom: 38px;
            }
        }
    }
}

.spc-snippet-top {
    .#{$ex} {
        &__controls {
            @include bp(min-width, $bp-medium) {
                bottom: 60px;
            }

            @include bp(min-width, $bp-large) {
                bottom: 80px;
            }
        }
    }
}

.spc-snippet-bottom {
    .#{$ex} {
        &__controls {
            @include bp(min-width, $bp-medium) {
                bottom: 100px;
            }
        }
    }
}
